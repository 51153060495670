/* @import url('https://fonts.googleapis.com/css2?family=Inter+Tight&family=PT+Sans&display=swap'); */


/* :root { */
/*     --color-primary: #c0c0c0e5; */
/*     --color-secondary: #fff8f2; */
/*     --color-text: #000000; */
/*     --font-primary: 'PT Sans', sans-serif; */
/* } */

/* * { */
/*     box-sizing: border-box; */
/*     margin: 0; */
/*     padding: 0; */
/* } */

/* html { */
/*     font-size: 16px; */
/*     line-height: 1; */
/* } */

/* body { */
/*     background: linear-gradient(190deg, */
/*             #edd7bf 100%, */
/*             var(--color-secondary) 0%); */
/*     color: var(--color-text); */
/*     font-family: var(--font-primary); */
/*     background-repeat: no-repeat; */
/*     background-size: cover; */
/*     background-attachment: fixed; */
/*     color: #222; */
/*     overflow: auto; */
/* } */

.App {
    position: relative;
    /* padding-top: 1rem; */
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center; 
    justify-content: center; 
    min-height: 100vh; 
}

.App h1 {
    display: flex;
    align-self: center;
    text-align: center;
    margin-top: 2vh;
    margin: 1vh;
    color: #fff8f2;
    text-shadow: 0 0 5px #1e3f5a, 3px -1px 5px #271c6c
}


.Scoreboard {
    padding: 1vh;
}

.team-selection h2, .Controls h2, .Scoreboard h2 {
    align-self: center;
    text-align: center;
    color: #fff8f2;
    text-shadow: 0 0 5px #1e3f5a, 3px -1px 5px #271c6c
}

.TeamScores {
    justify-content: space-between;
    flex-direction: row;
    justify-content: stretch;

}

.Score {
    display: flex;
    justify-content: space-around;
    margin: 0 6vw 0 6vw;
    background-color: var(--color-primary);
    padding: 2vh;
    border-radius: 1.5rem;

}

.Score h3 {
    justify-content: space-between;
}

.points {
    align-self: center;
    text-align: center;
    left: 50%;
    color: #f45d00;
    padding: 1vh;
    font-size: 4.5rem;
    -webkit-text-stroke: 0.01rem black;
    text-shadow: 0 0 5px #1e3f5a, 3px -1px 5px #271c6c
}

.displayteams {
    justify-content:space-around;
    align-items: center;
    padding: 2vh 0 4vh 0;
}

.TeamSelector {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TeamSelector button {
    padding: 0.5rem 1.2rem;
    border-radius: 20px;
    cursor: pointer;
    border: 0.5px solid var(--color-text);
    font-size: 1.1rem;
}

.TeamSelector .buttons button :focus {
    outline: none;
}


.Selector {
    display: flex;
    height: 100%;
    margin: 1vh;
    padding: 0 2vh 0 0;
    align-items: center;
    justify-content: space-between;
}

.SelectPlayer {
    height: 7vh;
    width: 45vw;
    /* font-size: 1.6rem; */
    border-radius: 25px;
    background-color: var(--color-primary);
}

.player {
    min-width: 15vh;
    width: 40vw;

    max-height: 7vh;
    background-color: #ffffffd3;
    border-radius: 1rem;
    border-color: #000000;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    text-align: center;
    align-self: center;
}

.playerpage {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.71);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    /* background-color: #e2e2e22d; */
    backdrop-filter: blur(10px);

    /* justify-content: space-between; */
    align-items: center;
    border-radius: 10px;
    text-align: center;
    align-content: center;
    padding: 0 2rem 0 2rem;

}

.title {
    display: flex;
    display: inline;
    width: 100%;
    text-align: center;
    padding: 1.5vh;
    font-size: 1.6rem;
    color: #fff8f2;
    text-shadow: 0 0 5px #1e3f5a, 3px -1px 5px #271c6c
}

.playerlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.player-btn {
    display: flex;
    justify-self: center;
    align-items: center;
    font-size: 2.5vw;
    background-color: #acacac;
    border-radius: 2rem;
    margin: 2rem;
    height: 12vw;
    width: 12vw;
    padding: 0.7rem;
    cursor: pointer;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);

}

.Controls {
    display: flex;
    display: inline-block;
    justify-content: space-around;
    padding: 0 2vh 0 2vh;
    text-align: center;
    align-items: center;
}

.Remote {
    font-size: 2.2vh;
    ;
}

.addpoints {
    /* display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center; */

}

.stat {
    /* display: inline; */
    /* align-items: center;
    justify-content: space-between;
    padding: 2vw;
    background-color: #0000002d;
    border-radius: 15px;
    font-size: 1.6rem;
    color: #fff8f2;
    text-align: center;
    text-shadow: 0 0 5px #1e3f5a, 3px -1px 5px #271c6c */
}

.otherstat {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.card {
    /* display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    background-color: #ffffffb6;
    margin: 0.2rem 0 0 0;
    height: 8vh;
    width: 25vw;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1rem;
    background-color: #a0a0a0;
    color: #fff8f2;
    text-shadow: 0 0 5px #1e3f5a, 3px -1px 5px #271c6c;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s ease-in-out;
    cursor: pointer; */
}

.card-in {
    /* display: flex;
    align-items: center;
    /* flex-direction: column; */
    /* border-radius: 15px; */
    /* margin: 0.2rem 0 0 0; */
    /* height: 8vh; */
    /* width: 25vw; */
    /* font-size: 2.2rem; */
    /* padding: 1rem; */
    /* background-color: #bdbdbd; */
    /* border-color: #000000; */
    /* -webkit-text-stroke: 0.4px black; */
    /* color: #fff; */
    /* text-shadow: 0 0 5px #1e3f5a, 3px -1px 5px #271c6c; */
    /* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); */
    /* transition: transform 0.2s ease-in-out; */
    /* cursor: pointer; */
}

.card-miss {
    /* display: flex; */
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    background-color: #ffffffb6;
    margin: 0.2rem 0 0 0;
    height: 8vh;
    width: 25vw;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1rem;
    background-color: #444444;
    /* green */
    color: #fff;
    /* white */
    text-shadow: 0 0 5px #1e3f5a, 3px -1px 5px #271c6c;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.button {
    /* display: inline-block;
    align-items: center;
    border-radius: 15px;
    background-color: #ffffffb6;
    margin: 0.2rem 0 0 0;
    height: 8vh;
    width: 25vw;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: #a0a0a0;
    color: #fff8f2;
    text-shadow: 0 0 5px #1e3f5a, 3px -1px 5px #271c6c;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.2s ease-in-out;
    cursor: pointer; */
}

table {

    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1.3rem;
    line-height: 1.4;
    font-weight: normal;
    color: #333;
    background-color: #ffffffc2;
}

table th,
table td {
    padding: 10px 15px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #ccc;
}

table th {
    background-color: #f7f7f7;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

table tr:hover {
    background-color: #f5f5f5;
}


table td:last-child,
table th:last-child {
    text-align: right;
}

table td:nth-child(2),
table th:nth-child(2) {
    text-align: center;
}

table td:nth-child(3),
table th:nth-child(3) {
    text-align: center;
}

table td:nth-child(4),
table th:nth-child(4) {
    text-align: center;
}

table td:nth-child(5),
table th:nth-child(5) {
    text-align: center;
}

.number {
    font-weight: bold;
    color: #000000;
}

form.team-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

form.team-selection input[type="text"] {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}


form.team-selection input[type="number"] {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

form.team-selection button[type="submit"] {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  background-color: #ffffffb6;
  margin: 0.2rem 0 0 0;
  width: 25vw;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1rem;
  background-color: #a0a0a0;
  color: #fff8f2;
  text-shadow: 0 0 5px #1e3f5a, 3px -1px 5px #271c6c;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

form.team-selection button[type="submit"]:hover {
  background-color: #45a049;
}

.App .loading {
  color: white;
}

.player-adder-button {
  margin-left: 1rem !important;
}

.player-number-container {
  margin-left: 1rem;
}

.error {
  color: #cc0000;
  text-align: left;
}

.player-selection-close {
  cursor: pointer;
  font-size: 1.6rem;
  color: #fff8f2;
  text-shadow: 0 0 5px #1e3f5a, 3px -1px 5px #271c6c;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
}
